import React, { useState } from "react";
import { stockupGalleryProduct } from "src/api/dashboard/gallery";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import Input from "src/modules/dashboard/components/Input";
import ModalLayout from "src/modules/dashboard/components/ModalLayout";
import { queryClient } from "src/utils";
import { galleryDealTypes } from "src/utils/constants";
const StockupGalleryProductModal = ({
  isVisible,
  setIsVisible,
  product,
  brandId,
  deal,
}) => {
  console.log({ product });
  const [productDetails, setProductDetails] = useState({
    originalPrice: product?.originalPrice || 0,
    sellingPrice: product?.sellingPrice || 0,
    quantity: 0,
  });

  return (
    <>
      <ModalLayout
        show={isVisible}
        onHide={() => {
          setIsVisible(false);
        }}
        title={`Restock ${product?.title}`}
      >
        <Input
          placeholder={"Original Price"}
          onChange={(e) => {
            setProductDetails({
              ...productDetails,
              originalPrice: parseFloat(e.target.value),
            });
          }}
          name={"originalPrice"}
          trailingLabel={"EGP"}
          type="number"
          defaultValue={product?.originalPrice}
          showPlaceholderOutside
          min={1}
        />
        {deal === galleryDealTypes.SPACE_PRICE && (
          <Input
            placeholder={"Selling Price"}
            onChange={(e) => {
              setProductDetails({
                ...productDetails,
                sellingPrice: parseFloat(e.target.value),
              });
            }}
            name={"sellingPrice"}
            trailingLabel={"EGP"}
            type="number"
            defaultValue={product?.sellingPrice}
            showPlaceholderOutside
            min={1}
          />
        )}
        <Input
          onChange={(e) => {
            setProductDetails({
              ...productDetails,
              quantity: parseInt(e.target.value),
            });
          }}
          placeholder={"Quantity"}
          name={"quantity"}
          type="number"
          min={1}
        />
        <ActionButton
          onClick={async () => {
            if (await stockupGalleryProduct(product?._id, productDetails)) {
              await queryClient.invalidateQueries({
                queryKey: ["brandProducts", { brandId }],
              });
              await queryClient.invalidateQueries({
                queryKey: ["galleryStatistics", brandId],
              });
              
              setIsVisible(false);
            }
          }}
          title={"Restock"}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            fontSize: 16,
            fontFamily: "Aoboshi One",
            margin: "auto",
            marginTop: 30,
          }}
          backgroundColor="colorSecondaryDarker"
        />
      </ModalLayout>
    </>
  );
};

export default StockupGalleryProductModal;
