import React from "react";
import Lottie from "react-lottie";
import animationData from "src/assets/lotties/kiss-of-the-heart.json";

export default function Loader({ size }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="d-flex align-items-center">
      <Lottie
        options={defaultOptions}
        width={size ?? 400}
        height={size ?? 400}
      />
    </div>
  );
}
