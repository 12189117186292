import MainDashboardLayout from "src/modules/dashboard/layouts/MainDashboardLayout";
import { useState } from "react";
import "./index.scss";
import { FaPlus } from "react-icons/fa";
import { queryClient } from "../../../../utils/index";
import EventBox from "./components/EventBox";

const Events = () => {
  const [isNewEventModalVisible, setIsNewModalVisible] = useState(false);

  return (
    <MainDashboardLayout slug={"Events"} className="events-page">
      <div className="events-container">
        <div className="side-events">
          <button
            className="new-event-btn"
            onClick={() => setIsNewModalVisible(true)}
          >
            New Event <FaPlus className="white m-l-2" />
          </button>
        </div>
        <div className="events-list">
          <EventBox />
          <EventBox />
          <EventBox />
        </div>
      </div>
    </MainDashboardLayout>
  );
};

export default Events;
