import React from "react";
import Login from "src/modules/dashboard/screens/Login";
import { Navigate, Route, Routes } from "react-router-dom";
import TicketDetails from "src/modules/space/screens/TicketDetails";
import Reception from "src/modules/dashboard/screens/Reception";
import EventForm from "src/modules/dashboard/screens/FormEvents";
import { ToastContainer } from "react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import HomeScreen from "src/modules/space/screens/Home";
import KitchenMenu from "src/modules/space/screens/Menu";
import PaymentPage from "src/modules/space/screens/EventPage";
import Kitchen from "./modules/dashboard/screens/Kitchen";
import { useAuth } from "src/contexts/AuthContext";
import { QueryClientProvider } from "react-query";
import MainDashboardLayout from "./modules/dashboard/layouts/MainDashboardLayout";
import { queryClient } from "./utils";
import Gallery from "./modules/dashboard/screens/Gallery";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "rsuite/dist/rsuite.min.css";
import Expenses from "./modules/dashboard/screens/Expenses";
import Customers from "./modules/dashboard/screens/Customers";
import EventTickets from "./modules/space/screens/EventTickets";
import EventTicketsDashboard from "./modules/dashboard/screens/EventTickets";
import Events from "./modules/dashboard/screens/Events";
import Statistics from "./modules/dashboard/screens/Statistics";
dayjs.extend(customParseFormat);

const spaceRoutes = [
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "menu",
    element: <KitchenMenu />,
  },
  {
    path: "events/:eventId/payment",
    element: <PaymentPage />,
  },
  {
    path: "events/:eventId/tickets",
    element: <EventTickets />,
  },
  {
    path: "events/:eventId/ticket-details/:ticketId",
    element: <TicketDetails />,
  },

  { path: "forms/:formId", element: <EventForm /> },
  {
    path: "*",
    element: (
      <div className=" text-center">
        <h1 className=" fw-bolder  text-black bg-white ">
          404 <br />
          Page Not FOUND
        </h1>
      </div>
    ),
  },
];

const dashboardRoutes = [
  {
    path: "portal/reception",
    element: <Reception />,
  },
  {
    path: "portal/customers",
    element: <Customers />,
  },
  {
    path: "portal/gallery",
    element: <Gallery />,
  },
  {
    path: "portal/kitchen",
    element: <Kitchen />,
  },
  {
    path: "portal/expenses",
    element: <Expenses />,
  },
  {
    path: "portal/revenue",
    element: <MainDashboardLayout />,
  },
  {
    path: "portal/reservations",
    element: <MainDashboardLayout />,
  },
  {
    path: "portal/events",
    element: <Events />,
  },
  {
    path: "portal/events/:eventId/tickets",
    element: <EventTicketsDashboard />,
  },
  {
    path: "portal/statistics",
    element: <Statistics />,
  },
  ...spaceRoutes.filter(({ path }) => path !== "login"),
  {
    path: "login",
    element: <Navigate to="/portal/reception" replace />,
  },
];

const App = () => {
  const { user } = useAuth();

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        {user
          ? dashboardRoutes.map(({ element, path }) => (
              <Route element={element} path={path} />
            ))
          : spaceRoutes.map(({ element, path }) => (
              <Route element={element} path={path} />
            ))}
      </Routes>
      <ToastContainer autoClose={1500} />
    </QueryClientProvider>
  );
};

export default App;
