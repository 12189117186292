import { getSpaceStatistics } from "src/api/dashboard/space";
import MainDashboardLayout from "../../layouts/MainDashboardLayout";
import { useQuery } from "react-query";
import StatsNumberBox from "../../components/StatsNumberBox";
import "./index.scss";

const Statistics = () => {
  const { data: statistics, isLoading } = useQuery({
    queryKey: "galleryBrands",
    queryFn: getSpaceStatistics,
  });

  return (
    <MainDashboardLayout
      slug={"Statistics"}
      className="statistics-page"
      isLoading={isLoading}
    >
      <div className="date-section">
        <h3>Today</h3>
        <div>
          <StatsNumberBox
            title="Visitors"
            numberIdentifier="visitors"
            comparedText="yesterday"
            comparedNumber={
              statistics?.todayVisitors - statistics?.yesterdayVisitors
            }
            mainNumber={statistics?.todayVisitors}
          />
          <StatsNumberBox
            title="Revenue"
            numberIdentifier="L.E"
            comparedText="yesterday"
            comparedNumber={
              statistics?.todayRevenue - statistics?.yesterdayRevenue
            }
            mainNumber={statistics?.todayRevenue}
          />
          <StatsNumberBox
            title="Expenses"
            numberIdentifier="L.E"
            comparedText="yesterday"
            comparedNumber={
              statistics?.todayExpenses - statistics?.yesterdayExpenses
            }
            mainNumber={statistics?.todayExpenses}
          />
          <StatsNumberBox
            title="Net Revenue"
            numberIdentifier="L.E"
            comparedText="yesterday"
            comparedNumber={
              statistics?.todayNetRevenue - statistics?.yesterdayNetRevenue
            }
            mainNumber={statistics?.todayNetRevenue}
          />
        </div>
        <span className="line-separator" />
      </div>
      <div className="date-section">
        <h3>Yesterday</h3>
        <div>
          <StatsNumberBox
            title="Visitors"
            numberIdentifier="visitors"
            comparedText="yesterday"
            comparedNumber={
              statistics?.yesterdayVisitors - statistics?.yesterdayVisitors
            }
            mainNumber={statistics?.yesterdayVisitors}
          />
          <StatsNumberBox
            title="Revenue"
            numberIdentifier="L.E"
            comparedText="yesterday"
            comparedNumber={
              statistics?.yesterdayRevenue - statistics?.yesterdayRevenue
            }
            mainNumber={statistics?.yesterdayRevenue}
          />
          <StatsNumberBox
            title="Expenses"
            numberIdentifier="L.E"
            comparedText="yesterday"
            comparedNumber={
              statistics?.yesterdayExpenses - statistics?.yesterdayExpenses
            }
            mainNumber={statistics?.yesterdayExpenses}
          />
          <StatsNumberBox
            title="Net Revenue"
            numberIdentifier="L.E"
            comparedText="yesterday"
            comparedNumber={
              statistics?.yesterdayNetRevenue - statistics?.yesterdayNetRevenue
            }
            mainNumber={statistics?.yesterdayNetRevenue}
          />
        </div>
        <span className="line-separator" />
      </div>
      <div className="date-section">
        <h3>This Month</h3>
        <div>
          <StatsNumberBox
            title="Visitors"
            numberIdentifier="visitors"
            comparedText="last month"
            comparedNumber={
              statistics?.thisMonthVisitors - statistics?.lastMonthVisitors
            }
            mainNumber={statistics?.thisMonthVisitors}
          />
          <StatsNumberBox
            title="Revenue"
            numberIdentifier="L.E"
            comparedText="last month"
            comparedNumber={
              statistics?.thisMonthRevenue - statistics?.lastMonthRevenue
            }
            mainNumber={statistics?.thisMonthRevenue}
          />
          <StatsNumberBox
            title="Expenses"
            numberIdentifier="L.E"
            comparedText="last month"
            comparedNumber={
              statistics?.thisMonthExpenses - statistics?.lastMonthExpenses
            }
            mainNumber={statistics?.thisMonthExpenses}
          />
          <StatsNumberBox
            title="Net Revenue"
            numberIdentifier="L.E"
            comparedText="last month"
            comparedNumber={
              statistics?.thisMonthNetRevenue - statistics?.lastMonthNetRevenue
            }
            mainNumber={statistics?.thisMonthNetRevenue}
          />
        </div>
        <span className="line-separator" />
      </div>
      <div className="date-section">
        <h3>Last Month</h3>
        <div>
          <StatsNumberBox
            title="Visitors"
            numberIdentifier="visitors"
            comparedText="last month"
            comparedNumber={
              statistics?.lastMonthVisitors - statistics?.lastMonthVisitors
            }
            mainNumber={statistics?.lastMonthVisitors}
          />
          <StatsNumberBox
            title="Revenue"
            numberIdentifier="L.E"
            comparedText="last month"
            comparedNumber={
              statistics?.lastMonthRevenue - statistics?.lastMonthRevenue
            }
            mainNumber={statistics?.lastMonthRevenue}
          />
          <StatsNumberBox
            title="Expenses"
            numberIdentifier="L.E"
            comparedText="last month"
            comparedNumber={
              statistics?.lastMonthExpenses - statistics?.lastMonthExpenses
            }
            mainNumber={statistics?.lastMonthExpenses}
          />
          <StatsNumberBox
            title="Net Revenue"
            numberIdentifier="L.E"
            comparedText="last month"
            comparedNumber={
              statistics?.lastMonthNetRevenue - statistics?.lastMonthNetRevenue
            }
            mainNumber={statistics?.lastMonthNetRevenue}
          />
        </div>
        <span className="line-separator" />
      </div>
      <div className="date-section">
        <h3>This Year</h3>
        <div>
          <StatsNumberBox
            title="Visitors"
            numberIdentifier="visitors"
            comparedText="last year"
            comparedNumber={
              statistics?.thisYearVisitors - statistics?.lastYearVisitors
            }
            mainNumber={statistics?.thisYearVisitors}
          />
          <StatsNumberBox
            title="Revenue"
            numberIdentifier="L.E"
            comparedText="last year"
            comparedNumber={
              statistics?.thisYearRevenue - statistics?.lastYearRevenue
            }
            mainNumber={statistics?.thisYearRevenue}
          />
          <StatsNumberBox
            title="Expenses"
            numberIdentifier="L.E"
            comparedText="last year"
            comparedNumber={
              statistics?.thisYearExpenses - statistics?.lastYearExpenses
            }
            mainNumber={statistics?.thisYearExpenses}
          />
          <StatsNumberBox
            title="Net Revenue"
            numberIdentifier="L.E"
            comparedText="last year"
            comparedNumber={
              statistics?.thisYearNetRevenue - statistics?.lastYearNetRevenue
            }
            mainNumber={statistics?.thisYearNetRevenue}
          />
        </div>
        <span className="line-separator" />
      </div>
      <div className="date-section">
        <h3>Last Year</h3>
        <div>
          <StatsNumberBox
            title="Visitors"
            numberIdentifier="visitors"
            comparedText="last year"
            comparedNumber={
              statistics?.lastYearVisitors - statistics?.lastYearVisitors
            }
            mainNumber={statistics?.lastYearVisitors}
          />
          <StatsNumberBox
            title="Revenue"
            numberIdentifier="L.E"
            comparedText="last year"
            comparedNumber={
              statistics?.lastYearRevenue - statistics?.lastYearRevenue
            }
            mainNumber={statistics?.lastYearRevenue}
          />
          <StatsNumberBox
            title="Expenses"
            numberIdentifier="L.E"
            comparedText="last year"
            comparedNumber={
              statistics?.lastYearExpenses - statistics?.lastYearExpenses
            }
            mainNumber={statistics?.lastYearExpenses}
          />
          <StatsNumberBox
            title="Net Revenue"
            numberIdentifier="L.E"
            comparedText="last year"
            comparedNumber={
              statistics?.lastYearNetRevenue - statistics?.lastYearNetRevenue
            }
            mainNumber={statistics?.lastYearNetRevenue}
          />
        </div>
        <span className="line-separator" />
      </div>
      <div className="date-section">
        <h3>Overall</h3>
        <div>
          <StatsNumberBox
            title="Visitors"
            numberIdentifier="visitors"
            comparedText="last year"
            comparedNumber={
              statistics?.overallVisitors - statistics?.overallVisitors
            }
            mainNumber={statistics?.overallVisitors}
          />
          <StatsNumberBox
            title="Revenue"
            numberIdentifier="L.E"
            comparedText="last year"
            comparedNumber={
              statistics?.overallRevenue - statistics?.overallRevenue
            }
            mainNumber={statistics?.overallRevenue}
          />
          <StatsNumberBox
            title="Expenses"
            numberIdentifier="L.E"
            comparedText="last year"
            comparedNumber={
              statistics?.overallExpenses - statistics?.overallExpenses
            }
            mainNumber={statistics?.overallExpenses}
          />
          <StatsNumberBox
            title="Net Revenue"
            numberIdentifier="L.E"
            comparedText="last year"
            comparedNumber={
              statistics?.overallNetRevenue - statistics?.overallNetRevenue
            }
            mainNumber={statistics?.overallNetRevenue}
          />
        </div>
        <span className="line-separator" />
      </div>
    </MainDashboardLayout>
  );
};

export default Statistics;
