import React from "react";

import "./index.scss";

const Input = ({
  value,
  onChange,
  placeholder,
  name,
  type = "text",
  style = {},
  trailingLabel,
  defaultValue,
  showPlaceholderOutside = false,
  subTitle,
  ...rest
}) => {
  return (
    <div className="input-container">
      {showPlaceholderOutside && <p>{subTitle || placeholder}</p>}
      <input
        type={type}
        name={name}
        className="input-component"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        style={style}
        {...rest}
      />
      {trailingLabel && <div className="trailing-label">{trailingLabel}</div>}
    </div>
  );
};

export default Input;
