import { createContext, useContext, useState, useEffect } from "react";
import { login as loginApi } from "../../api/dashboard/auth";
import { storageKeys } from "src/utils";

const AuthContext = createContext(0);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [accessToken, setAccessToken] = useState(undefined);

  useEffect(() => {
    const accessTokenFromStorage = localStorage.getItem(
      storageKeys.ACCESS_TOKEN
    );
    const userFromStorage = localStorage.getItem(storageKeys.USER);

    if (userFromStorage && accessTokenFromStorage) {
      setUser(JSON.parse(userFromStorage));
      setAccessToken(accessTokenFromStorage);
    }
  }, []);

  const login = async ({ username, password }) => {
    const { user, accessToken } = await loginApi({ username, password });
    setUser(user);
    setAccessToken(accessToken);

    localStorage.setItem(storageKeys.ACCESS_TOKEN, accessToken);
    localStorage.setItem(storageKeys.USER, JSON.stringify(user));

    window.location.href = "/portal/reception";
  };

  return (
    <AuthContext.Provider value={{ user, login, love: 1 }}>
      {children}
    </AuthContext.Provider>
  );
};
