import { method } from "lodash";
import { executeWithAuth } from "src/api";
import { API_URL_DASHBOARD } from "src/utils";

export const getSpacePasses = ({
  start,
  end,
  status,
  paginationToken,
  limit,
}) =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_DASHBOARD}/space/passes`,
    query: {
      start,
      end,
      status,
      paginationToken,
      limit,
    },
  }).then((data) => data.passes);

export const searchSpaceCustomers = ({ searchQuery }) =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_DASHBOARD}/space/customers/search`,
    query: {
      searchQuery,
    },
  }).then((data) => data?.spaceCustomers || []);

export const getSpaceCustomers = ({ limit, paginationToken }) =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_DASHBOARD}/space/customers`,
    query: {
      limit,
      paginationToken,
    },
  }).then((data) => data?.customers || []);

export const createSpacePass = ({
  customerId,
  name,
  phone,
  startTime,
  friends,
  knowAboutUsSource,
  ...rest
}) =>
  executeWithAuth({
    method: "POST",
    url: `${API_URL_DASHBOARD}/space/pass`,
    notifySuccessMsg: true,
    body: {
      customerId,
      name,
      phone,
      startTime,
      friends,
      knowAboutUsSource,
      ...rest,
    },
  }).then((data) => data?.pass);

export const payForSpacePass = ({
  kitchenProducts,
  passAmount,
  payments,
  customer,
  spacePassId,
  endTime,
}) =>
  executeWithAuth({
    notifySuccessMsg: true,
    method: "POST",
    url: `${API_URL_DASHBOARD}/space/pass/${spacePassId}/pay`,
    body: { kitchenProducts, passAmount, payments, customer, endTime },
  }).then((data) => data?.pass);

export const editSpacePass = ({
  spacePassId,
  phone,
  countryCode = "+20",
  friends,
  knowAboutUsSource,
  startTime,
  name,
}) =>
  executeWithAuth({
    method: "POST",
    url: `${API_URL_DASHBOARD}/space/pass/${spacePassId}`,
    body: {
      phone,
      countryCode,
      friends,
      knowAboutUsSource,
      startTime,
      name,
    },
  }).then((data) => data?.pass);

export const getSpaceStatistics = () =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_DASHBOARD}/space/statistics`,
  }).then((data) => data?.statistics);
