import { getSpaceCustomers } from "src/api/dashboard/space";
import DataTable from "../../components/InfiniteDataTable";
import { default as MainDashboardLayout } from "../../layouts/MainDashboardLayout";
import dayjs from "dayjs";

const Customers = () => {
  return (
    <>
      <MainDashboardLayout slug={"Customers"}>
        <div className="customers-page">
          <DataTable
            fetchData={getSpaceCustomers}
            dataKey={"spaceCustomers"}
            columns={[
              { title: "#", selector: (row) => row.customerId },
              { title: "Name" },
              { title: "Phone" },
              { title: "Email", selector: (row) => row?.email || "N/A" },
              {
                title: "Created At",
                selector: (row) =>
                  dayjs(row?.createdAt).format("dd DD/MM/YYYY hh:mma"),
              },
            ]}
          />
        </div>
      </MainDashboardLayout>
    </>
  );
};

export default Customers;
