import ActionButton from "src/modules/dashboard/components/ActionButton";
import "./index.scss";
import dayjs from "dayjs";
import { IoIosLink } from "react-icons/io";
import { notify } from "src/utils";
import { useNavigate } from "react-router-dom";

const EventBox = ({
  title = "Ozma Show 2nd Standup Comedy Open Mic Night at Aura Space",
  description = `Get ready for an uproarious evening of laughter and entertainment at Aura Space! Join us for our special event, Open Mic Night with a Standup Comedy Theme, hosted by the hilarious Ozma Show @ozma.show.
📅 Date: Saturday, 26th April...`,
  date = "2024-04-06T19:00:00.000+00:00",
  remainingSeats = 13,
  price = 200,
  eventId = 14,
  seats = 40,
  imageUrl = "https://i.ibb.co/LR9C8kL/OPEN-MIC-AURA1.png",
}) => {
  const navigate = useNavigate();
  return (
    <div className="event-box">
      <div className="content">
        <div className="title-container">
          <h1>{title}</h1>
          <span>#{eventId}</span>
        </div>
        <p className="description">{description}</p>
        <p className="date">{dayjs(date).format("dddd DD/MM/YYYY hh:mma")}</p>
        <div className="footer">
          <div>
            <h5>Tickets Sold</h5>
            <span>
              {remainingSeats}/{seats}
            </span>
            <span className="price">{price} L.E</span>
          </div>
          <div>
            <ActionButton
              title="Show Tickets"
              backgroundColor="colorPrimaryLight"
              onClick={() => navigate(`/portal/events/${eventId}/tickets`)}
              style={{ paddingRight: 20, paddingLeft: 20 }}
            />
            <ActionButton
              title={
                <>
                  Copy url
                  <IoIosLink style={{ marginLeft: 5 }} />
                </>
              }
              backgroundColor="colorPrimaryLight"
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://auraspace.art/events/${eventId}/payment`
                );
                notify("Url copied to clipboard", "success");
              }}
              style={{ paddingRight: 20, paddingLeft: 20 }}
            />
          </div>
        </div>
      </div>
      <div className="thumbnail-container">
        <img src={imageUrl} alt="title" />
      </div>
    </div>
  );
};

export default EventBox;
