import { useParams } from "react-router-dom";
import MainDashboardLayout from "../../layouts/MainDashboardLayout/index";
import ActionButton from "../../components/ActionButton";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import DataTable from "../../components/InfiniteDataTable";
import StatsNumberBox from "../../components/StatsNumberBox";

const EventTickets = () => {
  const { eventId } = useParams();
  return (
    <MainDashboardLayout
      slug={`Events / ${eventId}`}
      className="event-tickets-page"
    >
      <div className="event-tickets-side">
        <div className="title-label">Details</div>
        <div className="details-box">
          <div className="header">
            <p>#14</p>
            <span />
            <p>200L.E</p>
            <span />
            <p>Tuesday, 18th August</p>
            <span />
            <p>8:00pm</p>
          </div>
          <h2 className="event-title">
            Ozma Show 2nd Standup Comedy Open Mic Night at Aura Space
          </h2>
          <p className="event-description">
            Get ready for an uproarious evening of laughter and entertainment at
            Aura Space! Join us for our special event, Open Mic Night with a
            Standup Comedy Theme, hosted by the hilarious Ozma Show @ozma.show.
            📅 Date: Saturday, 7th April 🕘 Time: 9:00 PM 📍 Location: Aura
            Space
          </p>
          <ActionButton
            className="edit-btn"
            backgroundColor="colorPrimaryLight"
            title={
              <>
                <span>Edit </span>
                <MdEdit style={{ marginLeft: 5 }} />
              </>
            }
          />
        </div>
        <br />
        <br />
        <br />
        <div className="title-label">Statistics</div>
        <div className="stats-boxes">
          <StatsNumberBox
            title="Sold Tickets"
            mainNumber={13}
            numberIdentifier="ticket"
          />
          <br />
          <StatsNumberBox
            title="Sold Tickets"
            mainNumber={13}
            numberIdentifier="ticket"
          />
          <br />
          <StatsNumberBox
            title="Sold Tickets"
            mainNumber={13}
            numberIdentifier="ticket"
          />
        </div>
      </div>
      <div className="event-tickets-tables">
        <div className="title-label">Tickets</div>
        <DataTable
          fetchData={() => [
            {
              name: "Mahmoud Elashmawy",
              email: "elashmawydev@gmail.com",
              phone: "01226568908",
              seats: 2,
              paymentMethod: "instaPay",
            },
          ]}
          columns={[
            { title: "Name" },
            { title: "Phone" },
            { title: "Email" },
            { title: "Seats" },
            {
              title: "Payment Method",
              selector: (row) => (
                <div className={`payment-method-cell-${row.paymentMethod}`}>
                  {row.paymentMethod}
                </div>
              ),
            },
          ]}
          actions={[
            {
              component: (row) => (
                <ActionButton
                  onClick={() => {}}
                  title="Return"
                  backgroundColor="colorSecondaryDarker"
                />
              ),
            },
          ]}
        />
      </div>
    </MainDashboardLayout>
  );
};

export default EventTickets;
