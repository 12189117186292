import React, { useEffect, useState } from "react";
import MainDashboardLayout from "../../layouts/MainDashboardLayout";
import Select from "src/modules/dashboard/components/Select";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import { FaPlus } from "react-icons/fa";

import "./index.scss";
import DataTable from "../../components/InfiniteDataTable";
import { useQuery } from "react-query";
import { getBrandProducts, getGalleryBrands } from "src/api/dashboard/gallery";
import { redirect } from "react-router-dom";
import { galleryDealTypes } from "src/utils/constants";
import StatisticsSection from "./components/StatisticsSection";
import dayjs from "dayjs";
import BasicTooltip from "src/modules/dashboard/components/BasicTooltip";
import SalesSection from "./components/SalesSection";
import StockupGalleryProductModal from "./components/StockupGalleryProductModal";
import CreateGalleryProductModal from "./components/CreateGalleryProductModal";
import RegisterNewSaleModal from "./components/RegisterNewSaleModal";

const Gallery = () => {
  const {
    data: brands,
    isFetched,
    isLoading,
  } = useQuery({
    queryKey: "galleryBrands",
    queryFn: getGalleryBrands,
  });

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isStockupProductModalVisible, setIsStockupProductModalVisible] =
    useState(false);
  const [isCreateProductModalVisible, setIsCreateProductModalVisible] =
    useState(false);
  const [isRegisterNewSaleModal, setIsRegisterNewSaleModalVisible] =
    useState(false);

  useEffect(() => {
    if (!selectedBrand) {
      setSelectedBrand(brands?.[0]?._id);
    }
  }, [brands]);

  if (isFetched && brands.length === 0) {
    return redirect("/portal/reception");
  }

  return (
    <>
      <MainDashboardLayout
        slug={"Gallery"}
        className="gallery-page"
        isLoading={isLoading || !selectedBrand}
        extraHeaderContent={
          <div className="gallery-header-content">
            <h3>
              {brands?.find(({ _id }) => _id === selectedBrand)?.deal ===
              galleryDealTypes.DISCOUNT
                ? "20% Discount Deal"
                : "Space Price Deal"}
            </h3>
            <Select
              style={{ marginLeft: "auto", marginRight: 20, width: 170 }}
              defaultTitle="Select Brand"
              isTitleDisabled
              onChange={setSelectedBrand}
              // value={selectedBrand}
              options={brands?.map(({ name, _id }) => ({
                label: name,
                value: _id,
              }))}
            />
          </div>
        }
      >
        <StatisticsSection brandId={selectedBrand} />
        <div className="line-separator" />
        <div className="products-sales-body">
          <div className="products-container">
            <div className="section-header">
              <h3>Products</h3>
              <ActionButton
                onClick={() => setIsCreateProductModalVisible(true)}
                className="create-btn"
                title={
                  <p>
                    Create Product
                    <FaPlus size={12} />
                  </p>
                }
                backgroundColor="colorPrimaryLight"
              />
            </div>
            <div className="products-table">
              <DataTable
                dataKey="brandProducts"
                filters={{ brandId: selectedBrand }}
                fetchData={getBrandProducts}
                columns={[
                  {
                    title: "Title",
                  },
                  {
                    title: "Original Price",
                    selector: (row) => row?.originalPrice,
                  },
                  {
                    title: "Selling Price",
                    selector: (row) => row?.sellingPrice,
                  },
                  {
                    title: "Stock",
                    selector: (row) => row?.remainingStock,
                  },
                  {
                    title: "Sold Stock",
                    selector: (row) => row?.soldStock,
                  },
                  {
                    title: "Stock History",
                    selector: (row) => (
                      <div className="tooltip-container ">
                        {row?.restocks
                          ?.slice(
                            Math.max(
                              row?.restocks?.length -
                                (row?.restocks?.length > 4 ? 4 : 20),
                              0
                            )
                          )
                          ?.map((item) => (
                            <BasicTooltip
                              item={
                                <div className="item">
                                  {dayjs(item.date).format("DD MMM")}
                                </div>
                              }
                              data={
                                <div>
                                  OriginalPrice: {item.originalPrice} <br />
                                  Quantity: {item.quantity}
                                  <br />
                                  Date: {dayjs(item.date).format("DD/MM/YY")}
                                </div>
                              }
                            />
                          ))}
                      </div>
                    ),
                  },
                ]}
                actions={[
                  {
                    component: (row) => (
                      <ActionButton
                        onClick={() => {
                          setSelectedProduct(row);
                          setIsStockupProductModalVisible(true);
                        }}
                        title="Stock Up"
                        backgroundColor="colorPrimaryLight"
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div className="sales-container">
            <div className="section-header">
              <h3>Latest Sales</h3>
              <ActionButton
                onClick={() => setIsRegisterNewSaleModalVisible(true)}
                className="create-btn"
                title={
                  <p>
                    New Sale
                    <FaPlus size={12} />
                  </p>
                }
                backgroundColor="colorPrimaryLight"
              />
            </div>
            <SalesSection brandId={selectedBrand} />
          </div>
        </div>
        <StockupGalleryProductModal
          isVisible={isStockupProductModalVisible}
          setIsVisible={setIsStockupProductModalVisible}
          product={selectedProduct}
          brandId={selectedBrand}
          deal={brands?.find(({ _id }) => _id === selectedBrand)?.deal}
        />
        <CreateGalleryProductModal
          isVisible={isCreateProductModalVisible}
          setIsVisible={setIsCreateProductModalVisible}
          brandId={selectedBrand}
        />
        <RegisterNewSaleModal
          isVisible={isRegisterNewSaleModal}
          setIsVisible={setIsRegisterNewSaleModalVisible}
          brandId={selectedBrand}
        />
      </MainDashboardLayout>
    </>
  );
};

export default Gallery;
