import React, { useEffect } from "react";
import "./index.scss";
import LeftBottomVendor from "src/assets/images/Left-vector.png";
import AuraLogo from "src/assets/images/aura-logo.png";
import RightTopVendor from "src/assets/images/Right-vector-login.png";
import { useFormik } from "formik";
import { useAuth } from "src/contexts/AuthContext";

const Login = () => {
  const { login } = useAuth();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },

    onSubmit: async (values, { resetForm }) => {
      await login(values);
      resetForm();
    },
  });

  return (
    <div>
       
      <div className="login  d-flex justify-content-around align-items-center ">
        <div className="left-bottom-vendor">
          <img src={LeftBottomVendor} alt="" />
        </div>

        <div>
          <div className="aura-logo">
            <img src={AuraLogo} alt="" />
          </div>
          <div className="col-md-2  m-auto">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <input
                  type="username"
                  className="form-control"
                  id="username"
                  placeholder="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  required
                />
              </div>
              <button type="submit" className="btn w-50">
                Login
              </button>
            </form>
          </div>
        </div>

        <div className="right-top-vendor  ">
          <img className="" src={RightTopVendor} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Login;
