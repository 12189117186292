import MainDashboardLayout from "../../layouts/MainDashboardLayout";
import { FaPlus } from "react-icons/fa";
import "./index.scss";
import DataTable from "../../components/InfiniteDataTable";
import { useState } from "react";
import CreateExpenseModal from "./components/CreateExpenseModal";
import { listExpenses } from "src/api/dashboard/accounting";
import dayjs from "dayjs";

const Expenses = () => {
  const [newExpenseModal, setNewExpenseModal] = useState(false);
  return (
    <>
      <MainDashboardLayout slug={"Expenses"}>
        <div className="expenses-container">
          <div className="side-expenses">
            <button
              className="new-customer-btn"
              onClick={() => {
                setNewExpenseModal(true);
              }}
            >
              New Expense
              <FaPlus className="white m-l-2" />
            </button>
          </div>
          <div className="table-container">
            <DataTable
              dataKey="expenses"
              fetchData={listExpenses}
              sort={(row1, row2) =>
                dayjs(row1.date).isBefore(dayjs(row2.date)) ? 1 : -1
              }
              columns={[
                { title: "Title" },
                {
                  title: "Amount",
                  selector: (row) => (
                    <span className="amount-cell">
                      {row.amount}
                      <span>{row.currency}</span>
                    </span>
                  ),
                },
                {
                  title: "Date",
                  selector: (row) => (
                    <span>{dayjs(row.date).format("DD/MM/YYYY")}</span>
                  ),
                },
                {
                  title: "Category",
                  selector: (row) => (
                    <div className={`category-cell-${row.category}`}>
                      {row.category}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
        <CreateExpenseModal
          isVisable={newExpenseModal}
          setIsVisible={setNewExpenseModal}
        />
      </MainDashboardLayout>
    </>
  );
};
export default Expenses;
