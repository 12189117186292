import React from "react";
import Alogo from "src/assets/images/a-logo.png";
import { FaCashRegister } from "react-icons/fa6";
import { BsFillBasketFill } from "react-icons/bs";
import { TbFridge } from "react-icons/tb";
import { MdOutlineMoneyOffCsred } from "react-icons/md";
import { MdOutlineAttachMoney } from "react-icons/md";
import { TbCircleLetterR } from "react-icons/tb";
import { BsCalendarHeartFill } from "react-icons/bs";
import { MdOutlineShowChart } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";

import "./index.scss";
import { Link, useLocation } from "react-router-dom";

const taps = [
  {
    title: "Reception",
    path: "/portal/reception",
    icon: <FaCashRegister className="icon" />,
  },
  {
    title: "Customers",
    path: "/portal/customers",
    icon: <FaUserFriends className="icon" />,
  },
  {
    title: "Gallery",
    path: "/portal/gallery",
    icon: <BsFillBasketFill className="icon" />,
  },
  {
    title: "Kitchen",
    path: "/portal/kitchen",
    icon: <TbFridge className="icon" />,
  },
  {
    title: "Expenses",
    path: "/portal/expenses",
    icon: <MdOutlineMoneyOffCsred className="icon" />,
  },
  {
    title: "Revenue",
    path: "/portal/revenue",
    icon: <MdOutlineAttachMoney className="icon" />,
  },
  {
    title: "Reservations",
    path: "/portal/reservations",
    icon: <TbCircleLetterR className="icon" />,
  },
  {
    title: "Events",
    path: "/portal/events",
    icon: <BsCalendarHeartFill className="icon" />,
  },
  // {
  //   title: "Statistics",
  //   path: "/portal/statistics",
  //   icon: <MdOutlineShowChart className="icon" />,
  // },
];

export default function Navbar() {
  const location = useLocation();

  return (
    <div className="nav-bar-container">
      <div className="logo-container">
        <img src={Alogo} alt="" />
      </div>
      {taps.map(({ title, icon, path }) => (
        <Link
          to={path}
          className={
            (location.pathname === path ? "active " : "") + "icon-container"
          }
        >
          <h2>{title}</h2>
          {icon}
        </Link>
      ))}
    </div>
  );
}
